<template>
    <component :is="icon" v-if="icon" :class="finalClass" />
</template>

<script setup lang="ts">
import { computed, DefineComponent } from "vue";

import ArrowIcon from "~/components/icons/ArrowIcon.vue";
import ChevronLeftIcon from "./ChevronLeftIcon.vue";
import ChevronRightIcon from "./ChevronRightIcon.vue";
import CloseIcon from "./CloseIcon.vue";
import FacebookIcon from "~/components/icons/FacebookIcon.vue";
import GlobeIcon from "~/components/icons/GlobeIcon.vue";
import HomeIcon from "~/components/icons/HomeIcon.vue";
import InstagramIcon from "~/components/icons/InstagramIcon.vue";
import SearchIcon from "~/components/icons/SearchIcon.vue";
import YouTubeIcon from "~/components/icons/YouTubeIcon.vue";

const iconsMap: Record<string, DefineComponent> = {
    ArrowIcon: ArrowIcon as unknown as DefineComponent,
    ChevronLeftIcon: ChevronLeftIcon as unknown as DefineComponent,
    ChevronRightIcon: ChevronRightIcon as unknown as DefineComponent,
    CloseIcon: CloseIcon as unknown as DefineComponent,
    FacebookIcon: FacebookIcon as unknown as DefineComponent,
    GlobeIcon: GlobeIcon as unknown as DefineComponent,
    HomeIcon: HomeIcon as unknown as DefineComponent,
    InstagramIcon: InstagramIcon as unknown as DefineComponent,
    SearchIcon: SearchIcon as unknown as DefineComponent,
    YouTubeIcon: YouTubeIcon as unknown as DefineComponent,
};

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    size: {
        type: String,
        default: "any",
    },
    classes: {
        type: String,
        default: "",
    },

    // transition-colors hover:cursor-pointer hover:fill-accent
});

const sizeClasses: any = {
    xs: "h-3 w-3",
    sm: "h-4 w-4",
    md: "h-5 w-5",
    lg: "h-6 w-6",
    xl: "h-7 w-7",
    any: "",
};

const finalClass = computed(() => {
    const size = sizeClasses[props.size];

    return `${size} ${props.classes} overflow-hidden`;
});

const icon = computed(() => {
    if (!props.name) {
        return null;
    }

    // Find the icon in the iconMap by name
    const svgComponent = iconsMap[props.name];

    if (!svgComponent) {
        console.warn(`SvgIcon: icon "${props.name}" not found`);
        return null;
    }

    return svgComponent;
});
</script>
