<template>
    <svg
        width="800"
        height="800"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clip-rule="evenodd"
            d="M10.619 4.025a2.25 2.25 0 0 1 2.762 0l6 4.666a2.25 2.25 0 0 1 .869 1.776V20A1.25 1.25 0 0 1 19 21.25h-3.25V15a3.75 3.75 0 1 0-7.5 0v6.25H5A1.25 1.25 0 0 1 3.75 20v-9.533c0-.694.32-1.35.869-1.776zM9.002 22.75H5A2.75 2.75 0 0 1 2.25 20v-9.533a3.75 3.75 0 0 1 1.448-2.96l6-4.666a3.75 3.75 0 0 1 4.604 0l6 4.666a3.75 3.75 0 0 1 1.448 2.96V20A2.75 2.75 0 0 1 19 22.75h-4.002zm5.248-1.5h-4.5V15a2.25 2.25 0 0 1 4.5 0z"
            fill="#000"
            fill-rule="evenodd"
        />
    </svg>
</template>
